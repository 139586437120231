import React from 'react';
import { Box, Grid, Flex } from 'theme-ui';
import Stack from '../Stack';
import Text from '../Text';
import B from '../Button';
import Button from '../FilterButton';

function ExpandedFilter(props) {
  const {
    filters,
    contract,
    setFilter,
    hasFilterValue,
    resetFilters,
    updateFilter,
  } = props;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        bg: 'accent-3',
        p: 3,
      }}
    >
      <Grid columns={[1, 3]}>
        <Stack>
          <Text.H4>Plats</Text.H4>
          <Grid columns={3}>
            <Button
              active={hasFilterValue('camp', true)}
              onClick={() => setFilter('camp', true)}
              variant="outline"
              size="small"
            >
              Camp
            </Button>
            <Button
              active={hasFilterValue('guide', true)}
              onClick={() => setFilter('guide', true)}
              variant="outline"
              size="small"
            >
              Guide
            </Button>
          </Grid>
        </Stack>
        <Stack>
          <Text.H4>Fisken</Text.H4>
          <Flex sx={{ flexWrap: 'wrap' }}>
            {filters.fishSpecies.map((x) => (
              <Box key={x.id} sx={{ p: 1 }}>
                <Button
                  active={hasFilterValue('fishSpecies', x.id)}
                  onClick={() => setFilter('fishSpecies', x.id)}
                  variant="outline"
                  size="small"
                >
                  {x.name}
                </Button>
              </Box>
            ))}
          </Flex>
        </Stack>
        <Stack>
          <Text.H4>Metod</Text.H4>
          <Flex sx={{ flexWrap: 'wrap' }}>
            {filters.fishingMethods.map((x) => (
              <Box key={x.id} sx={{ p: 1 }}>
                <Button
                  active={hasFilterValue('fishingMethods', x.id)}
                  onClick={() => setFilter('fishingMethods', x.id)}
                  variant="outline"
                  size="small"
                >
                  {x.name}
                </Button>
              </Box>
            ))}
          </Flex>
        </Stack>
      </Grid>
      <Flex
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: ['center', 'flex-end'],
          py: [4, 0],
        }}
      >
        <Box sx={{ mr: 3 }}>
          <Text.Span
            onClick={() => {
              resetFilters();
              contract();
            }}
            sx={{
              color: 'accent-5',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Rensa
          </Text.Span>
        </Box>
        <Box>
          <B
            onClick={() => contract() || updateFilter()}
            variant="primary"
            size="medium"
          >
            Filtrera
          </B>
        </Box>
      </Flex>
    </Flex>
  );
}

export default ExpandedFilter;
