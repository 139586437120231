import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Box, Flex } from 'theme-ui';
import { useQuery } from 'react-query';
import camelize from 'camelcase-keys';
import { useResponsiveValue } from '@theme-ui/match-media';
import safeWindow from '../util/safeWindow';
import FilterRow, { getFilterCount } from '../components/FilterRow/connect';
import uspFactory from '../domain/services/uspFactory';
import useUrlSync, { parse, shapes } from '../hooks/useUrlSync';
import filterSearchQuery from '../queries/filterSearch';
import gql from '../util/graphqlClient';

import LocationListItem from '../components/LocationListItem';
import Link from '../components/Link';
import Icon from '../components/Icon';
import ContentBox from '../components/ContentBox';
import Map, { Marker } from '../components/Map';
import Text from '../components/Text';
import Stack from '../components/Stack';
import Layout from '../global/Layout';
import config from '../config';
import texts from '../text';
import { get } from '../util/get';
import { DataStatus } from '../components/DataStatus';
import { StickyFilterButton } from '../components/StickyFilterButton';
import { HighlightedItem } from '../components/HighlightedItem';
import { formatFilters } from '../util/formatFilters';
import { HideAfter } from '../components/HideAfter';
import { HideBelow } from '../components/HideBelow';

function getLocationIcon(type, icons) {
  return icons.find((x) => x.name === `${type}_result`);
}

function getMarkerIcon(type, icons) {
  return icons.find((x) => x.name === `${type}_pin`);
}

const uid = ({ type, id }) => type + id;

function getHighlightedItemProps({ data, icons }, id) {
  const place = data.find((x) => uid(x) === id);
  return {
    id: place?.id,
    name: place?.name,
    slug: place?.slug,
    usps: uspFactory(
      {
        icons,
        ...camelize(place),
      },
      texts
    ),
    description: place?.description,
    county: place?.location?.County,
    image: place?.profile_img,
    icon: getLocationIcon(place?.type, icons),
    housing: place?.housing,
  };
}

function sortByName(places) {
  return places.sort((a, b) => a.name.localeCompare(b.name));
}
function sortPricing(prices) {
  return prices.sort((a, b) => a - b);
}

function cheapestPrice(prices) {
  const sorted = sortPricing(prices);
  return sorted.length > 0 ? sorted[0] : 0;
}

const filterTypes = {
  fishingMethods: shapes.Array(shapes.String),
  fishSpecies: shapes.Array(shapes.String),
  guide: shapes.Array(shapes.Boolean),
  camp: shapes.Array(shapes.Boolean),
};

const setType = (type) => (item) => {
  item.type = type;
  return item;
};

const fetchCamps = gql(filterSearchQuery);

function FindPage({ data: { strapi } }) {
  const [hoveringItemId, setHoverItemId] = useState(false);
  const [selected, setSelected] = useState(false);
  const [expanded, toggleExpand] = useState(false);

  const mapZoomLevel = useResponsiveValue([
    config.map.defaultZoomLevel.mobile,
    config.map.defaultZoomLevel.desktop,
  ]);

  const [filters, setFilters] = useState({
    camp: [true],
    guide: [true],
    ...safeWindow((ctx) => parse(ctx.location.search, filterTypes)),
  });

  // todo: move to a more specific filter hook with shared state.
  const params = useUrlSync(
    {
      onHistoryReplace: (search) =>
        window.history.replaceState(null, null, `?${search}`),
      onUpdate: () => filters,
      types: filterTypes,
    },
    [filters]
  );

  React.useEffect(() => {
    setSelected(false);
  }, [filters]);

  const format = React.useCallback(() => formatFilters(params), [params]);

  const response = useQuery(['query', format()], fetchCamps(format()), {
    staleTime: 50000,
  });

  const {
    camps,
    guidings,
    images,
    icons: _icons,
    ...availableFilters
  } = strapi;

  const icons = [...images, ..._icons];

  let places = {
    data: sortByName([
      ...camps.map(setType('camp')),
      ...guidings.map(setType('guide')),
    ]),
  };
  if (response.status === 'success') {
    const { data = {} } = response.data;
    places = {
      data: sortByName([
        ...get(data, 'camps', []).map(setType('camp')),
        ...get(data, 'guidings', []).map(setType('guide')),
      ]),
    };
  }

  return (
    <Layout fullWidth>
      <StickyFilterButton
        filterCount={getFilterCount(filters)}
        onClick={() => toggleExpand(!expanded)}
      />
      {expanded ? (
        <>
          <HideAfter target="mobile">
            <Box
              sx={{
                flexDirection: 'column',
                bg: 'accent-3',
                position: 'fixed',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                zIndex: 22,
              }}
            >
              <Flex sx={{ justifyContent: 'flex-end', p: 2 }}>
                <Icon icon="close" onClick={() => toggleExpand(!expanded)} />
              </Flex>
              <FilterRow
                defaultExpanded
                handleContract={() => toggleExpand(!expanded)}
                setFilters={setFilters}
                filters={availableFilters}
                initialActiveFilters={filters}
              />
            </Box>
          </HideAfter>
        </>
      ) : null}

      <Flex
        sx={{
          flexDirection: ['column-reverse', null, 'row'],
          justifyContent: ['flex-start', null, 'flex-end'],
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: 2,
            minHeight: '100%',
            flexShrink: [null, 1, 0],
            flexBasis: (theme) => [null, '100%', theme.breakpoints[1]],
          }}
        >
          <HideBelow target="desktop">
            <FilterRow
              setFilters={setFilters}
              filters={availableFilters}
              initialActiveFilters={filters}
            />
          </HideBelow>

          <ContentBox>
            <DataStatus
              loading={response.status === 'loading'}
              dataCount={places.data.length}
            />
            <Box sx={{ px: [null, 0] }}>
              {selected ? (
                <>
                  <Box py={4}>
                    <HighlightedItem
                      {...getHighlightedItemProps(
                        { icons, data: places.data },
                        selected
                      )}
                    />
                  </Box>
                  <Text.H4>Flera platser</Text.H4>
                </>
              ) : null}
              <Stack>
                {places.data
                  .filter((x) => selected !== uid(x))
                  .map(
                    ({
                      id,
                      name,
                      type,
                      slug,
                      description,
                      location: { County },
                      profile_img: image,
                      housing = [],
                      ...rest
                    }) => (
                      <Link
                        sx={{
                          textDecoration: 'none',
                          py: 2,
                        }}
                        key={`${type}${id}`}
                        to={`/${slug}`}
                      >
                        <LocationListItem
                          onMouseEnter={() => setHoverItemId(uid({ id, type }))}
                          onMouseLeave={() => setHoverItemId(0)}
                          type={type}
                          icon={getLocationIcon(type, icons)}
                          usps={uspFactory(
                            {
                              icons: strapi.icons,
                              ...camelize(rest),
                            },
                            texts
                          )}
                          id={id}
                          selected={selected}
                          image={image}
                          name={name}
                          description={description}
                          county={County}
                          price={cheapestPrice(housing.map((x) => x.price))}
                        />
                      </Link>
                    )
                  )}
              </Stack>
            </Box>
          </ContentBox>
        </Box>

        <Box
          sx={{
            height: ['40vh', '40vh', 'calc(100vh - 55px)'],
            width: ['100%'],
            position: ['sticky'],
            top: '55px',
          }}
        >
          <Map center={config.map.defaultCenter} zoom={mapZoomLevel}>
            {places.data.map((x) => (
              <Marker
                key={uid(x)}
                icon={getMarkerIcon(x.type, icons)}
                hovering={hoveringItemId === uid(x)}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setSelected(uid(x));
                }}
                lat={x.location.latitude}
                lng={x.location.longitude}
              />
            ))}
          </Map>
        </Box>
      </Flex>
    </Layout>
  );
}
export default FindPage;

export const query = graphql`
  query FilterPageQuery {
    strapi {
      images(
        where: {
          name_in: ["guide_result", "guide_pin", "camp_pin", "camp_result"]
        }
      ) {
        id
        name
        image {
          url
        }
      }
      icons: images(where: { name_containss: "USP_" }) {
        id
        name
        image {
          url
        }
      }
      guidings {
        id
        name
        slug: name_slug
        description
        fish_species {
          name
          icon: image {
            image {
              url
            }
          }
        }
        fishing_methods {
          name
          icon: image {
            image {
              url
            }
          }
        }
        amenities: guide_amenities {
          name
          icon {
            image {
              url
            }
          }
        }
        location {
          latitude
          longitude
          Address
          County
        }
        website_link: webiste_link
        profile_img {
          url
          mime
          name
          provider_metadata
          provider
          hash
        }
      }
      camps {
        id
        name
        slug: nameslug
        description
        housing {
          price
          currency
        }
        fish_species {
          name
          icon: image {
            image {
              url
            }
          }
        }
        fishing_methods {
          name
          icon: image {
            image {
              url
            }
          }
        }
        has_boats
        amenities {
          name
          icon: image {
            image {
              url
            }
          }
        }
        location {
          latitude
          longitude
          Address
          County
        }
        website_link
        profile_img {
          url
          mime
          name
          provider_metadata
          provider
          hash
        }
      }
      fishingMethods {
        name
        id
      }
      fishSpecies {
        name
        id
      }
    }
  }
`;
